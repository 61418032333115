import { ServerToFarmType } from 'model/src/dataflowprotocol/servertofarm/ServerToFarmType';
import { Period } from 'model/src/timer/TimerTemplate';
import React, { useContext, useState } from 'react';
import Clock, {
  MILLISECONDS_PER_MINUTE,
  MINUTES_PER_DAY,
  MINUTES_PER_HOUR,
} from 'services/src/common/Clock';
import Logger from 'services/src/logging/Logger';
import { convertLocalToUtcMinutes } from 'services/src/timezone/TimezoneUtility';
import { v4 as uuid } from 'uuid';

import { getActionLabel } from '../../farm-ui/windows/SchedulerWindow';
import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import Toggle from '../toggle/Toggle';
import PrimaryButton from '../window/components/PrimaryButton';
import SecondaryButton from '../window/components/SecondaryButton';
import {
  SchedulerModalDropdownMenuButtonStyling,
  SchedulerModalDropdownMenuContainer,
  SchedulerModalDropdownMenuOption,
  SchedulerModalDropdownMenuStyling,
  SchedulerModalOptionBox,
  SchedulerModalOptionContainer,
  SchedulerModalPanel,
  SchedulerModalTestSubmissionBox,
} from './SchedulerModalStyledComponents';
import { ChangeLightLevelCommandFields } from './SchedulerTimerTemplateModal';

const AddTimerTemplateModalPanel = (props: any) => {
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const clock = injectionContext.clockFactory() as Clock;
  const coreLogger: Logger = injectionContext.coreLogger;

  const hours = props.modal.time.hours.toString().padStart(2, '0');
  const minutes = props.modal.time.minutes.toString().padStart(2, '0');
  const [time, setTime] = useState(hours + ':' + minutes);
  const [timerTemplateEnabled, setTimerTemplateEnabled] = useState(false);
  const [repeatTimerTemplate, setRepeatTimerTemplate] = useState(true);
  const [repeatPeriod, setRepeatPeriod] = useState<Period>(Period.DAY);

  const [commandFields, setCommandFields] = useState(props.modal.commandFields);
  const setCommandField = field => {
    setCommandFields({ ...commandFields, ...field });
  };

  const handleInputChange = event => {
    setTime(event.target.value);
  };

  const closeModal = () =>
    props.setModal({ commandType: null, updateType: null });

  const getPeriodOffsetMs = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    const minutesFromMidnight = hours * MINUTES_PER_HOUR + minutes;
    let utcMinutes = convertLocalToUtcMinutes(
      minutesFromMidnight,
      clock.getUtcOffsetMinutes()
    );
    if (utcMinutes >= MINUTES_PER_DAY) {
      utcMinutes = utcMinutes - MINUTES_PER_DAY;
    }
    return utcMinutes * MILLISECONDS_PER_MINUTE;
  };

  return (
    <>
      <SchedulerModalPanel>
        <SchedulerModalOptionContainer>
          <SchedulerModalOptionBox>
            {props.modal.commandFields.targetId}
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            New Command: {getActionLabel(props.modal.commandType, coreLogger)}
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            Enabled:
            <Toggle
              defaultPosition={timerTemplateEnabled}
              onChange={() => setTimerTemplateEnabled(value => !value)}
            />
          </SchedulerModalOptionBox>
          <SchedulerModalOptionBox>
            Time:
            <input
              type="time"
              id="timeInput"
              value={time}
              onChange={handleInputChange}
            />
          </SchedulerModalOptionBox>
          <TimerTemplateCommandSpecificFields
            commandType={props.modal.commandType}
            setCommandField={setCommandField}
            coreLogger={coreLogger}
          />
          <SchedulerModalTestSubmissionBox>
            <SecondaryButton
              onClick={() => {
                closeModal();
              }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                props.sendAddTimerTemplateCommand({
                  id: uuid(),
                  period: Period.DAY,
                  periodOffset_ms: getPeriodOffsetMs(time),
                  action: { ...commandFields },
                  actionType: props.modal.commandType,
                  repeats: true,
                  isEnabled: timerTemplateEnabled,
                  validations: [],
                  expirations: 0,
                });
                closeModal();
              }}>
              Submit
            </PrimaryButton>
          </SchedulerModalTestSubmissionBox>
        </SchedulerModalOptionContainer>
      </SchedulerModalPanel>
    </>
  );
};
export default AddTimerTemplateModalPanel;

const SchedulerModalPeriodDropdownMenu = ({
  repeatPeriod,
  setRepeatPeriod,
}) => {
  const [repeatMenuOpen, setRepeatMenuOpen] = useState(false);
  return (
    <SchedulerModalDropdownMenuContainer>
      <SchedulerModalDropdownMenuButtonStyling
        onClick={() => setRepeatMenuOpen(value => !value)}>
        {getPeriod(repeatPeriod)}
      </SchedulerModalDropdownMenuButtonStyling>
      {repeatMenuOpen && (
        <SchedulerModalDropdownMenuStyling>
          <SchedulerModalDropdownMenuOption
            onClick={() => {
              setRepeatPeriod(Period.DAY);
              setRepeatMenuOpen(false);
            }}>
            {getPeriod(Period.DAY)}
          </SchedulerModalDropdownMenuOption>
          <SchedulerModalDropdownMenuOption
            onClick={() => {
              setRepeatPeriod(Period.WEEK);
              setRepeatMenuOpen(false);
            }}>
            {getPeriod(Period.WEEK)}
          </SchedulerModalDropdownMenuOption>
          <SchedulerModalDropdownMenuOption
            onClick={() => {
              setRepeatPeriod(Period.MONTH);
              setRepeatMenuOpen(false);
            }}>
            {getPeriod(Period.MONTH)}
          </SchedulerModalDropdownMenuOption>
          <SchedulerModalDropdownMenuOption
            onClick={() => {
              setRepeatPeriod(Period.YEAR);
              setRepeatMenuOpen(false);
            }}>
            {getPeriod(Period.YEAR)}
          </SchedulerModalDropdownMenuOption>
        </SchedulerModalDropdownMenuStyling>
      )}
    </SchedulerModalDropdownMenuContainer>
  );
};

const getPeriod = (period: Period) => {
  switch (period) {
    case Period.DAY:
      return 'Daily';
    case Period.WEEK:
      return 'Weekly';
    case Period.MONTH:
      return 'Monthly';
    case Period.YEAR:
      return 'Yearly';
  }
};

export const TimerTemplateCommandSpecificFields = props => {
  switch (props.commandType) {
    case ServerToFarmType.AcOffCommand:
      return <></>;
    case ServerToFarmType.AcOnCommand:
      return <></>;
    case ServerToFarmType.ChangeLightLevelCommand:
      return (
        <ChangeLightLevelCommandFields
          setCommandField={props.setCommandField}
        />
      );
    case ServerToFarmType.CommandLineCommand:
      return (
        <CommandLineCommandFields setCommandField={props.setCommandField} />
      );
    case ServerToFarmType.DoorCloseCommand:
      return <></>;
    case ServerToFarmType.DoorOpenCommand:
      return <></>;
    case ServerToFarmType.DockCommand:
      return <></>;
    case ServerToFarmType.DrainCloseCommand:
      return <></>;
    case ServerToFarmType.DrainOpenCommand:
      return <></>;
    case ServerToFarmType.UndockCommand:
      return <></>;
    case ServerToFarmType.DriveCommand:
      return <DriveCommandFields setCommandField={props.setCommandField} />;
    case ServerToFarmType.DriveCommand2:
      return <DriveCommand2Fields setCommandField={props.setCommandField} />;
    case ServerToFarmType.FansOffCommand:
      return <></>;
    case ServerToFarmType.FansOnCommand:
      return <></>;
    case ServerToFarmType.GantryFindFarXCommand:
      return <></>;
    case ServerToFarmType.GantryFindFarYCommand:
      return <></>;
    case ServerToFarmType.GantryFindFarZCommand:
      return <></>;
    case ServerToFarmType.GantryGoToCommand:
      return (
        <GantryGoToCommandFields setCommandField={props.setCommandField} />
      );
    case ServerToFarmType.GantryHardStopCommand:
      return <></>;
    case ServerToFarmType.GantryHomeCommand:
      return <></>;
    case ServerToFarmType.GantryMoveByCommand:
      return (
        <GantryMoveByCommandFields setCommandField={props.setCommandField} />
      );
    case ServerToFarmType.GantryStopCommand:
      return <></>;
    case ServerToFarmType.InitializeCommand:
      return <></>;
    case ServerToFarmType.LiftCommand:
      return <LiftCommandFields setCommandField={props.setCommandField} />;
    case ServerToFarmType.LightsOffCommand:
      return <></>;
    case ServerToFarmType.LightsOnCommand:
      return <></>;
    case ServerToFarmType.NavigateCommand:
      return <NavigateCommandFields setCommandField={props.setCommandField} />;
    case ServerToFarmType.PumpOffCommand:
      return <></>;
    case ServerToFarmType.PumpOnCommand:
      return <></>;
    case ServerToFarmType.PumpAutoCommand:
      return <></>;
    case ServerToFarmType.PumpToEmptyCommand:
      return <></>;
    case ServerToFarmType.RebootCommand:
      return <></>;
    case ServerToFarmType.ResetDeadReckoningCommand:
      return <></>; //TODO(austin): does this need segmentId?
    case ServerToFarmType.ResetFlowInCommand:
      return <></>;
    case ServerToFarmType.ResetFlowOutCommand:
      return <></>;
    case ServerToFarmType.ShutDownCommand:
      return <></>;
    case ServerToFarmType.UmbilicalGantryHomeCommand:
      return <></>;
    case ServerToFarmType.UpdateFirmwareCommand:
      return <></>;
    case ServerToFarmType.ValveCloseCommand:
      return <></>;
    case ServerToFarmType.ValveOpenCommand:
      return <></>;
    default:
      props.coreLogger.info(
        'Scheduler::getModalFields: missing type: ' + props.commandType
      );
      return <></>;
  }
};

const CommandLineCommandFields = props => {
  const handleCommandLineCommandChange = e => {
    props.setCommandField({ commandLineCommand: { command: e.target.value } });
  };
  return (
    <SchedulerModalOptionBox>
      {'Command line argument: '} <input type="text" />
    </SchedulerModalOptionBox>
  );
};
const DriveCommandFields = props => {
  //TODO(austin): implement
  //TODO(austin): add enum dropdown
  const handleCommandTypeChange = e => {
    props.setCommandField({ commandType: e.target.value });
  };
  const handleSpeedChange = e => {
    props.setCommandField({ speed: e.target.value });
  };
  const handleMoveByChange = e => {
    props.setCommandField({ move: { byMm: e.target.value } });
  };
  const handleTurnByChange = e => {
    props.setCommandField({ turn: { byDeg: e.target.value } });
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'Command: '} <></>
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Speed: '} <input type="number" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'move by (mm): '} <input type="number" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'turn by (degrees): '} <input type="number" />
      </SchedulerModalOptionBox>
    </>
  );
};
const DriveCommand2Fields = props => {
  //TODO(austin): implement
  const handleLinearVelocityChange = e => {
    props.setCommandField({ linearVelocity: e.target.value });
  };
  const handleAngleChange = e => {
    props.setCommandField({ angle: e.target.value });
  };
  const handleAngularVelocityChange = e => {
    props.setCommandField({ angularVelocity: e.target.value });
  };
  const handleMaxWHeelSpeedChange = e => {
    props.setCommandField({ maxWheelSpeedMS: e.target.value });
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'Linear velocity: '} <input type="number" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Angle: '} <input type="number" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Angular velocity: '} <input type="number" />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Max. wheel speed: '} <input type="number" />
      </SchedulerModalOptionBox>
    </>
  );
};
const GantryGoToCommandFields = props => {
  const handleXChange = e => {
    props.setCommandField({ x_mm: e.target.value });
  };
  const handleYChange = e => {
    props.setCommandField({ y_mm: e.target.value });
  };
  const handleZChange = e => {
    props.setCommandField({ z_mm: e.target.value });
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'X (mm): '} <input type="number" min="0" onChange={handleXChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Y (mm): '} <input type="number" min="0" onChange={handleYChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Z (mm): '} <input type="number" min="0" onChange={handleZChange} />
      </SchedulerModalOptionBox>
    </>
  );
};
const GantryMoveByCommandFields = props => {
  const handleXChange = e => {
    props.setCommandField({ x_mm: e.target.value });
  };
  const handleYChange = e => {
    props.setCommandField({ y_mm: e.target.value });
  };
  const handleZChange = e => {
    props.setCommandField({ z_mm: e.target.value });
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'X (mm): '} <input type="number" min="0" onChange={handleXChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Y (mm): '} <input type="number" min="0" onChange={handleYChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Z (mm): '} <input type="number" min="0" onChange={handleZChange} />
      </SchedulerModalOptionBox>
    </>
  );
};
const LiftCommandFields = props => {
  const [shouldHome, setShouldHome] = useState(false);
  const handleAbsolutePositionChange = e => {
    props.setCommandField({ absolutePosition: e.target.value });
  };
  const handlePowerChange = e => {
    props.setCommandField({ power: e.target.value });
  };
  const handleWeightChange = e => {
    props.setCommandField({ weight: e.target.value });
  };
  const handleSpeedChange = e => {
    props.setCommandField({ speed: e.target.value });
  };
  const handleShouldHomeChange = () => {
    props.setCommandField({ shouldHome: !shouldHome });
    setShouldHome(!shouldHome);
  };
  return (
    <>
      <SchedulerModalOptionBox>
        {'Absolute position: '}{' '}
        <input type="number" min="0" onChange={handleAbsolutePositionChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Power: '} <input type="number" min="0" onChange={handlePowerChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Weight: '}{' '}
        <input type="number" min="0" onChange={handleWeightChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Speed: '} <input type="number" min="0" onChange={handleSpeedChange} />
      </SchedulerModalOptionBox>
      <SchedulerModalOptionBox>
        {'Should home: '}{' '}
        <Toggle
          defaultPosition={shouldHome}
          onChange={handleShouldHomeChange}
        />
      </SchedulerModalOptionBox>
    </>
  );
};
const NavigateCommandFields = props => {
  //TODO(austin): implement
  const handleNavigationTypeChange = e => {
    props.setCommandField({
      navigationalIntent: {
        ...props.commandField.NavigationalIntent,
        type: e.target.value,
      },
    });
  };
  const handleSpeedChange = e => {
    props.setCommandField({
      navigationalIntent: {
        ...props.commandField.NavigationalIntent,
        speedMps: e.target.value,
      },
    });
  };
  const handleDistanceChange = e => {
    props.setCommandField({
      navigationalIntent: {
        ...props.commandField.NavigationalIntent,
        distance: e.target.value,
      },
    });
  };
  return (
    //TODO(austin): add enum dropdown
    <>
      <SchedulerModalOptionBox>
        {'Navigation type: '} <></>
        {'Speed: '} <input type="number" />
        {'Distance: '} <input type="number" />
      </SchedulerModalOptionBox>
    </>
  );
};
